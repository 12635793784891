.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.central-div {
  margin-top: 15px;
  background: var(--color-4);
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 10px;
  border-radius: 15px;
  transition: all .3s;
}

.other-div {
  margin-top: 30px;
  background: #9ac0d1b7;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 10px;
  border-radius: 15px;
  transition: all .3s;
}

.row-mb-5 {
  margin-bottom: 5px;
}

.button-mr-5 {
  margin-right: 5px;
}

.justify-rigth {
  text-align: right;
}

.justify-left {
  text-align: left;
}

.tabs-container {
  left: 0px;
  top: 80px;
  right: 0px;
  bottom: 0px;
  position: absolute;
  overflow: hidden;
  font-size: 14px;
}

.button-mr-5 {
  margin-right: 5px;
}

.panel {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(28, 216, 230);
  border: 5px solid rgb(38, 71, 133);
  box-sizing: border-box;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.color-fondo {
  color: rgba(171, 179, 245);
  background-color: #23426b;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 5px;
  border: 5px solid rgb(9, 31, 3)
}

.selector {
  display: flex;
  align-items: center;
  justify-content: center;
}

.password {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: nowrap;
  align-content: space-around;
}

.table {
  table-layout: auto !important;
  color: inherit !important;
  --bs-table-hover-color: #F08047;
  --bs-table-hover-bg: var(--color-3);
  border-color: var(--color-6);
}

.table th {
  font-size: 20px;
  border: 1;
}

.table td {
  border: 0;
}

.btn-primary {
  background-color: #3461a3;
  border-color: #3461a3;
}

.btn-danger {
  background-color: #b9434e;
  border-color: #b9434e;
}

.row {
  margin-bottom: 10px;
}

.form-control {
  background-color: var(--color-6);
  color: inherit !important;
}

.form-control:focus {
  background-color: var(--color-4);
}

.date-picker-input {
  padding: 8px;
  background-color: var(--color-6);
  color: inherit !important;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  text-align: center;
}

.form-select {
  background-color: var(--color-6);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23c5c5c5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  color: inherit !important;
}

.form-select:disabled {
  background-color: var(--color-3);
}

.form-control:disabled {
  background-color: var(--color-3);
}

.text-align-center {
  text-align: center;
}

.modal-content {
  background-color: var(--color-3);
}

.modal-content .btn-close {
  background-color: var(--color-6)
}

.title-and-buttons {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-column-gap: 5px;
  justify-items: center;
}

.title-and-buttons .title {
  grid-column-start: 2;
}

.title-and-buttons .buttons {
  margin-left: auto;
  margin-bottom: auto;
  margin-top: auto;
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: repeat(5, auto);
}

.admin-table-header th span.sr-only {
  display: none;
}

.admin-table-header input.number-filter-input {
  width: 100% !important;
}

.custom-dropdown-item {
  background-color: var(--color-6);
  color: white;
}

.custom-dropdown-item:hover,
.custom-dropdown-item:focus {
  background-color: var(--color-4);
  color: white;
}

.custom-dropdown-item.active {
  background-color: var(--color-3);
}

.dropdown-menu {
  padding: 0;
  border: none;
  box-shadow: none;
  background-color: transparent;
  border-radius: 15px;
}

.dropdown-menu>li>a {
  color: inherit;
  padding: 8px 20px;
  white-space: nowrap;
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
  background-color: var(--color-4);
  color: white;
  text-decoration: none;
}

.dropdown-menu>li:first-child>a {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.dropdown-menu>li:last-child>a {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}